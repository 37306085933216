/* You can add global styles to this file, and also import other style files */
@import "~ngx-toastr/toastr";

@import "./assets/scss/app.scss";

html,
body {
  padding: 0;
  margin: 0;
}
body {
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f0f1fe;
}

.card-refresh:before {
  visibility: visible;
  opacity: 1;
}

.card-refresh:after {
  content: "";
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border: 3px solid #e6ecf5;
  /* Light grey */
  border-top: 3px solid #0912b1;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 3000;
  opacity: 1;
  -webkit-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.t-h1 {
  font-size: 45px;
}

.b-500 {
  font-weight: 500;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row-center {
  display: flex;
  align-items: center;
}

.f-row {
  display: flex;
  flex-direction: row;
}
.f-column {
  display: flex;
  flex-direction: column;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.t-muted {
  color: grey;
}

/* common dashboard classes*/
.main-wrapper {
  width: 100%;
  max-width: 1020px;
  margin-left: auto !important;
  margin-right: auto !important;
  /* background-color: red; */
  padding: 1rem 2rem;
  height: calc(100vh - 65px);
}
.top-section {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  border-top: 1px solid #edf4f9 !important;
}
.back-btn {
  border-radius: 50%;
  background-color: #fff;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0;
  color: #777;
  user-select: none;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.back-btn:hover {
  background-color: #f1f1fb;
}

.route-label {
  font-size: 1.3em;
  color: #111;
  font-weight: 600;
  width: 100%;
  max-width: 1020px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 1.2rem 2rem;
  /* margin-left: 2rem; */
}
.back-btn-icon {
  font-size: 0.85em;
}

.no-data-badge {
  margin: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(119, 119, 119);
  font-size: 0.9em;
}
.ndb-icon {
  border-radius: 50%;
  background-color: #deddff;
  color: #5952ff;
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.5em;
}

.c-link {
  color: #5952ff !important;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .main-wrapper {
    width: 100%;
    max-width: 1020px;
    margin-left: auto !important;
    margin-right: auto !important;
    /* background-color: red; */
    padding: 10px 15px;
    height: calc(100vh - 65px);
  }
  .back-btn {
    border-radius: 50%;
    background-color: #f6f6fd;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 0;
    color: #23233c;
    user-select: none;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .back-btn:hover {
    background-color: #f1f1fb;
  }

  .route-label {
    font-size: 1.2em;
    margin-left: 1rem;
    padding: 10px 20px;
    font-size: 14px;
  }
  .back-btn-icon {
    font-size: 0.7em;
  }
}


.text-primary-dv-color {
  color: #443bf9;
}

.secondary-black-text {
  color: #6b6b6b;
}